<style lang="less">
.answer_record {
  background: #f6fafe;
  height: 100vh;
  .answer_container {
    padding: 20px;
    .total_group {
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: @shadow;
      font-weight: bold;
      .title {
        font-size: 24px;
        span {
          color: @mainColor;
        }
      }
      .title_ul {
        margin-top: 20px;
        li {
          display: inline-block;
          width: 200px;
          font-size: 18px;
          span {
            color: @mainColor;
          }
        }
      }
    }
    .answer_content {
      .el-collapse-item {
      }
      .collapse_header {
        font-size: 16px;
        display: inline-block;
        padding-left: 20px;
      }
      .user-select {
        user-select: none;
      }
      .collapse_content {
        .answer_desc {
          background-color: #f2f2f2;
          font-size: 16px;
          font-weight: bold;
          color: #3b4553;
          padding: 8px 20px;
        }
        .answer_items {
          .items_title_group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            padding: 20px;
            > div:first-child {
              display: flex;
              div,
              p {
                display: flex;
              }
              img {
                max-width: 300px;
                display: inline-block;
                margin: 0 10px;
              }
            }
            .give_score {
              font-weight: bold;
              font-size: 18px;
              margin-right: 30px;
            }
          }
          .items_radio {
            margin-left: 50px;
            .el-radio {
              display: block;
              margin-bottom: 15px;
            }
          }
          .items_answer_group {
            padding-left: 50px;
            > div:first-child {
              margin-bottom: 20px;
              .questionKey {
                display: inline-flex;
              }
              p {
                display: inline-block;
                margin-right: 50px;
                font-size: 16px;
                font-weight: 500;
                color: #999;
              }
            }
            .parse {
              font-size: 16px;
              color: #999;
              font-weight: 500;
            }
          }
        }
        .success {
          color: #77c325;
        }
        .error {
          color: #af3737;
        }
      }
    }
  }
}
</style>
<template>
  <div class="answer_record">
    <StudentHeader :videoFlag="true" />
    <div class="answer_container" v-loading="loading">
      <div class="total_group">
        <div class="title">
          总得分：<span>{{ items.stuCore }}分</span> 试卷名称：<span>{{
            items.workName
          }}</span>
          答题要求：<span v-html="items.workDesc"></span>
        </div>
        <!-- <ul class="title_ul">
          <li v-for="(item, index) in eduTestPaperQuestions" :key="index">
            {{ item.questionName }}：<span>{{ item.stuItemScore }}分</span>
          </li>
        </ul> -->
      </div>
      <div class="answer_content">
        <el-collapse v-model="active">
          <el-collapse-item
            v-for="(edu, index) in eduTestPaperQuestions"
            :key="index"
            :name="edu.id"
          >
            <template slot="title">
              <span class="collapse_header" v-html="edu.questionName"></span>
            </template>
            <div class="collapse_content">
              <div class="answer_desc">
                答题要求：<span v-html="edu.answerDesc"></span>
              </div>
              <div
                class="answer_items"
                v-for="(c, i) in edu.eduCourseQuestionVos"
                :key="i"
              >
                <div class="items_title_group">
                  <div>
                    <p style="width: 28px; height: 28px">
                      <svg-icon
                        v-if="c.subjectiveFlag === 1"
                        :name="
                          c.correctFlag === 1
                            ? 'success'
                            : c.correctFlag === 0
                            ? 'error'
                            : ''
                        "
                      ></svg-icon>
                    </p>
                    <p>{{ i + 1 }}. ({{ c.itemScore }}分)&nbsp;&nbsp;</p>
                    <p v-html="c.questionTitle"></p>
                  </div>
                  <p
                      v-if="c.enabled === 1"
                    class="give_score"
                    :class="
                      c.correctFlag === 1 || c.stuItemScore
                        ? 'success'
                        : c.correctFlag === 0 || !c.stuItemScore
                        ? 'error'
                        : ''
                    "
                  >
                    +{{ c.stuItemScore }}分
                  </p>
                </div>
                <el-radio-group class="items_radio">
                  <el-radio
                    v-for="(r, i) in c.options"
                    :key="i"
                    :label="r.id"
                    disabled
                  >
                    {{ r.itemKey }}. {{ r.itemVal }}
                  </el-radio>
                </el-radio-group>
                <div class="items_answer_group">
                  <div>
                    <p>我的答案：{{ c.answerVal }}</p>
                    <p class="questionKey">
                      参考答案：<span
                        class="success"
                        v-html="c.questionKey"
                      ></span>
                    </p>
                  </div>
                  <div class="parse">
                    答案解析：<br />
                    <span v-html="c.questionAnalyze"></span>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from "@/components/student/Header.vue";
import {
  QueryStudentExamRecordDetails, // 在线考试 （貌似不用这个接口）
  QueryStudentWorkRecordDetails, // 在线作业
} from "@/libs/api/online";
import { QueryStudentWorkById } from "@/libs/api/teacher/check";
export default {
  name: "answer_record",
  components: { StudentHeader },
  data() {
    return {
      loading: false,
      items: {
        // totalScore: 0,
        stuCore: 0,
        workName: null,
        workDesc: null,
      },
      // bigQuestions: [],
      eduTestPaperQuestions: [],
    };
  },
  computed: {
    active() {
      return this.eduTestPaperQuestions.map((m) => m.id);
      // return this.bigQuestions.map((m) => m.id);
    },
    questions() {
      return this.eduTestPaperQuestions.map((m) => m.questionsName);
      // return this.bigQuestions.map((m) => m.questionsName);
    },
  },
  mounted() {
    this.init();
  },
  //
  methods: {
    init() {
      this.loading = true;
      const { id, studentId } = this.$route.query;
      QueryStudentWorkById({ id, studentId })
        .then((res) => {
          this.loading = false;
          if (!res.data) return;
          this.eduTestPaperQuestions = res.data.eduTestPaperQuestions;
          this.items = res.data;
          // this.bigQuestions = res.data.bigQuestions;
          // this.items.totalScore = res.data.totalScore;
        })
        .catch(() => {
          this.$message.error("服务器错误，请稍后重试。");
          this.loading = false;
        });
    },
    old_init() {
      this.loading = true;
      const { id, studentId } = this.$route.query;
      QueryStudentWorkRecordDetails(id)
        .then((res) => {
          this.loading = false;
          if (!res.data) return;
          this.bigQuestions = res.data.bigQuestions;
          this.items.totalScore = res.data.totalScore;
        })
        .catch(() => {
          this.$message.error("服务器错误，请稍后重试。");
          this.loading = false;
        });
    },
  },
};
</script>